import Logo from './components/Logo'
import Title from './components/Title'
import Experiments from './components/Experiments'
import Studiogusto from './three/Studiogusto'
import useFontPreload from './hooks/useFontPreload'
import './style/style.scss'


const App = () => {
  const isFontReady = useFontPreload({ fonts: ['Matter'] })
 
  return (
    <>
      <div className="wrapper">
        <Logo />
        <Title ready={isFontReady} />
        <Experiments ready={isFontReady} />
      </div>
      <Studiogusto ready={isFontReady} />
    </>
  )
}

export default App
