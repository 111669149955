import { useEffect, useRef, useState } from 'react'
import FontFaceObserver from 'fontfaceobserver'

const useFontPreload = ({
  fonts,
}) => {
  const fontsToPreload = useRef([])
  const [ isFontReady, setFontReady ] = useState(false)

  useEffect(() => {
    if (fonts.length > 0 && !isFontReady) {
      fonts
        .map((font) => {
          fontsToPreload.current.push(new FontFaceObserver(font))
          return null
        })
      Promise.all(fontsToPreload.current
        .map((f) => f.load()))
        .then(() => {
          setFontReady(true)
        })
    }
  }, [fonts, isFontReady])

  return isFontReady
}

useFontPreload.defaultProps = {
  fonts: [],
}

export default useFontPreload
