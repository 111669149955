import { useState, useMemo, useRef, useEffect } from 'react'
import { useFrame, Canvas, useThree } from '@react-three/fiber'
import { useTexture } from '@react-three/drei'
import { Uniform, RepeatWrapping, MathUtils } from 'three'
import gsap from 'gsap'

const Logo = () => {
	const [isHover, setHover] = useState(false)
	const ref = useRef()
	const { viewport } = useThree()
	const { lerp } = MathUtils
	const myMouse = useRef({
		x: 0,
		oldX: 0,
		speedX: 0
	})

	const logoTexture = useTexture('./images/logo/logo-big.png', (a) => {
		a.wrapS = RepeatWrapping
		a.wrapT = RepeatWrapping
	})

	const shaderArgs = useMemo(() => {
		return {
			uniforms: {
				uLogoTexture: new Uniform(logoTexture),
				uTime: new Uniform(0),
				uSpeed: new Uniform(0),
				uMouse: new Uniform({ x: 0, y: 0 })
			},
			vertexShader: /* glsl */ `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelMatrix * viewMatrix * vec4(position, 1.0);
        }
      `,
			fragmentShader: /* glsl */ `
        varying vec2 vUv;
        uniform sampler2D uLogoTexture;
        uniform float uTime;
        uniform float uSpeed;
        uniform vec2 uMouse;

        void main() {
          vec2 uv = vUv;

          float dist =  smoothstep(-.1, vUv.x, vUv.x-.1) *
                        mix(1., .9, smoothstep(.9, 1.1, vUv.x));
          float m =  smoothstep(0.4,0.5,vUv.x-uMouse.x)-smoothstep(0.5,.6,vUv.x-uMouse.x);

          uv.x *= dist;
          uv.x += 0.02;

          uv.x -= 1.5;
          uv.x *= 1. + (m * .15 * uSpeed);
          uv.x += 1.5;

          float aberration = 0.05 * uSpeed * m * (sin(uv.y * 10. + uTime * 10.) * 1.1);
          float r = 1. - texture(uLogoTexture, uv + vec2(uTime * 0.075 - aberration, -aberration)).r;
          float g = 1. - texture(uLogoTexture, uv + vec2(uTime * 0.075, 0.)).r;
          float b = 1. - texture(uLogoTexture, uv + vec2(uTime * 0.075 + aberration, aberration)).r;

          vec3 col = clamp(vec3(r,g,b), .0, 1.);
          gl_FragColor = vec4(col, 1.);
        }
    `
		}
	}, [])

	useFrame(({ mouse, clock }) => {
		if (ref.current) {
			ref.current.material.uniforms.uTime.value = clock.getElapsedTime()

			if (isHover) {
				const x = mouse.x * 0.5

				myMouse.current.x = lerp(myMouse.current.x, x, 0.2)
				myMouse.current.oldX = lerp(myMouse.current.oldX, x, 0.05)

				// Mouse Move RGB
				ref.current.material.uniforms.uMouse.value.x = myMouse.current.x
				ref.current.material.uniforms.uSpeed.value = myMouse.current.x - myMouse.current.oldX // prettier-ignore
			} else {
				ref.current.material.uniforms.uSpeed.value = lerp(ref.current.material.uniforms.uSpeed.value, 0, 0.05) // prettier-ignore
			}
		}
	})

	return (
		<mesh
			scale={[viewport.width, viewport.height, 1]}
			ref={ref}
			onPointerOver={() => {
				setHover(true)
			}}
			onPointerOut={() => {
				setHover(false)
			}}>
			<planeGeometry />
			<shaderMaterial args={[shaderArgs]} transparent={true} />
		</mesh>
	)
}
const Studiogusto = ({ ready }) => {
	const [$root, setRoot] = useState()

	useEffect(() => {
		if (!$root && !ready) return

		gsap.to($root, {
			autoAlpha: 1,
			delay: 4.2,
			duration: 2,
			ease: 'power2.out'
		})
	}, [$root, ready])

	return (
		<div className='canvas' ref={setRoot}>
			<Canvas dpr={[1, 2]}>
				<Logo />
			</Canvas>
		</div>
	)
}

export default Studiogusto
