const experiments = [
  {
    name: 'Asics Poster',
    link: 'https://www.supah.it/asics-poster/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/asics-poster.webp',
  },
  {
    name: 'Biglia',
    link: 'https://supahfunk.github.io/biglia/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/biglia.webp',
  },
  {
    name: 'Breathe',
    link: 'https://www.supah.it/breathe/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/breathe-ball.webp',
  },
  {
    name: 'Countdown',
    link: 'https://codepen.io/supah/full/abVGjVq',
    tags: ['svg', 'codepen'],
    image: 'images/items/countdown.webp',
  },
  {
    name: 'Curl',
    link: 'https://www.supah.it/curl/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/curl.webp',
  },
  {
    name: 'Erba',
    link: 'https://www.supah.it/erba/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/erba.webp',
  },
  {
    name: 'Fading Balls',
    link: 'https://codepen.io/supah/full/yLermNo',
    tags: ['webgl', 'threejs', 'shaders', 'codepen'],
    image: 'images/items/fading-balls.webp',
  },
  {
    name: 'Ghosted O',
    link: 'https://www.shadertoy.com/view/NtyyWW',
    tags: ['webgl', 'shadertoy', 'shaders'],
    image: 'images/items/ghosted-o.webp',
  },
  {
    name: 'Infinite',
    link: 'https://www.supah.it/infinite/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/infinite.webp',
  },
  {
    name: 'Infinity Grid',
    link: 'https://www.supah.it/infinity-grid/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/infinity-grid.webp',
  },
  {
    name: 'Inside Outside',
    link: 'https://www.supah.it/inside-outside/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/inside-outside.webp',
  },
  {
    name: 'Magma',
    link: 'https://www.supah.it/magma/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/magma.webp',
  },
  {
    name: 'Oval',
    link: 'https://www.supah.it/oval/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/oval.webp'
  },
  {
    name: 'Parametric Spider',
    link: 'https://codepen.io/supah/full/vYdwbQK',
    tags: ['canvas', 'codepen'],
    image: 'images/items/parametric-spider.webp'
  },
  {
    name: 'Playground',
    link: 'https://9cenu.csb.app/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/playground.webp'
  },
  {
    name: 'Refraction',
    link: 'https://www.supah.it/refraction/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/refraction.webp'
  },
  {
    name: 'Spiral',
    link: 'https://www.supah.it/spiral/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/vortex.webp'
  },
  {
    name: 'Twist & Shout',
    link: 'https://www.supah.it/twistshout/',
    tags: ['webgl', 'threejs', 'shaders'],
    image: 'images/items/twist-shout.webp'
  }
]

export default experiments
