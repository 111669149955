import { useEffect, useState } from "react"
import gsap from 'gsap'

const Word = ({ className, index, ready }) => {
  const [$root, setRoot] = useState()

  useEffect(() => {
    if (!$root && !ready) return
    const divs = $root.querySelectorAll('div')
    gsap.set(divs, {
      y: '100%',
      x: '-100%',
      transformOrigin: '0% 0%',
    })

    gsap.to(divs, {
      y: '0%',
      x: '0%',
      duration: 1.5,
      ease: 'expo.out',
      stagger: 0.05,
      delay: 1.5 + index* 0.2
    })
  }, [$root, ready])

  const top = 100 - 15 * index
  const bottom = top - 16
  const clipPath = `polygon(0% ${top}%, 100% ${top}%, 100% ${bottom}%, 0% ${bottom}%)`
	return (
		<div ref={setRoot} className={`title-line ${className}`} style={{clipPath: clipPath }}>
			{'Speakeasy'.split('').map((l, i) => (
				<div key={i.toString()}>{l}</div>
			))}
		</div>
	)
}

const Title = ({ready}) => {
	return (
		<h1 className='title'>
			<div className='title-line-reference'>Speakeasy</div>
      {Array(6).fill().map((_, i) => (
        <Word ready={ready} key={i.toString()} index={i} />
      ))}
		</h1>
	)
}

export default Title
