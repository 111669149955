import experiments from '../../data/experiments'
import Item from '../Item'

const Experiments = ({ready}) => {

  return (
    <section>
      <div 
       className="experiments"
      >
        {experiments.map((exp, i) => {
          const num = i + 1 < 10 ? `0${i + 1}` : i + 1
          return (
            <div className="experiment" key={i.toString()}>
              <Item ready={ready} index={i} image={exp.image} link={exp.link} name={`<em>${num}</em> ${exp.name}`} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Experiments