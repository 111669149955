import { useRef, useState, useEffect } from 'react'
import gsap from 'gsap'
import HTMLParse from 'react-html-parser'

const Item = ({ link, name, ready, index, image }) => {
  const $overlay = useRef()
  const $link = useRef()

  const [$root, setRoot] = useState()

  useEffect(() => {
    if (!$root && !ready) return

    const delay = 3 + 0.05 * index
    const duration = 2

    gsap.set($root, {
      y: '50px',
    })

    gsap.to($root, {
      y: 0,
      delay: delay,
      duration: duration,
      ease: 'power4.out',
    })
    gsap.to($root, {
      opacity: 1,
      delay: delay,
      duration: duration,
    })
  }, [$root, ready])


  const handleMouseEnter = (e) => {
    const bounds = $root.getBoundingClientRect()
    const top = e.clientY < bounds.y + bounds.height / 2

    gsap.to($link.current, {
      x: '2rem',
      duration: 0.5,
      ease: 'power3.out'
    })
    gsap.fromTo(
      $overlay.current,
      {
        scaleY: 0,
        transformOrigin: top ? '0 0' : '0 100%'
      },
      {
        scale: 1,
        duration: 0.5,
        ease: 'power3.out'
      }
    )
  }

  const handleMouseLeave = (e) => {
    const bounds = $root.getBoundingClientRect()
    const top = e.clientY < bounds.y + bounds.height / 2

    gsap.killTweensOf([$overlay.current, $link.current])
    gsap.to($link.current, {
      x: 0,
      duration: 0.3,
      ease: 'power3.out'
    })
    gsap.to($overlay.current, {
      scaleY: 0,
      transformOrigin: top ? '0 0' : '0 100%',
      duration: 0.7,
      ease: 'power3.out'
    })
  }

  return (
    <a onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} href={link} target="_blank">
      <div ref={setRoot} >
        {image && (
          <img src={image} alt={name} />
        )}
        <span className="link" ref={$link}>
          {HTMLParse(name)}
        </span>
        <span className="overlay" ref={$overlay}></span>
      </div>
    </a>
  )
}

export default Item
